@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap);
* {
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:hover {
  text-decoration: none !important;
}

